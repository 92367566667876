import React from 'react';
import loadable from '@loadable/component';

const Switchback = ({ id, data }) => {
	switch (data?.sectionType) {
		case 'about':
			const About = loadable(() => import('./components/About'));
			return <About key={id} data={data} />;
		case 'contact':
			const Contact = loadable(() => import('./components/Contact'));
			return <Contact key={id} data={data} />;
		default:
			return null;
	}
};

export default Switchback;
